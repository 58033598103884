<template>
    <div class="head">
        <div class="main">
            <div class="head_top">
                <img src="../../views/home/image/cbs-logo.png" alt="">
                <div>
                    <div class="text">出版热线：</div>
                    <div class="phone">
                        <img src="../../views/home/image/cbs-phone.png" alt="">
                        186-6013-3094
                    </div>
                </div>
            </div>
            <ul class="head_bom">
                <li v-for="(item, index) in navList" :key="index">
                    <div class="link" :class="{'active': navCurrentIndex === index}"
                    @click="directTo(item.route)">
                        {{item.name}}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    navCurrentIndex:{
        type:Number,
        default:0
    }
  },
  data() {
    return {
        navList:[
            {
                name:'网站首页',
                route:'/'
            },
            {
                name:'服务项目',
                route:'/serve'
            },
            {
                name:'出版流程',
                route:'/flowpath'
            },
            {
                name:'成书展示',
                route:'/book'
            },
            {
                name:'出版资讯',
                route:'/new'
            },
            {
                name:'关于我们',
                route:'/about'
            }
        ],
    };
  },
  mounted(){
    
  },
  methods: {
    directTo(route){
       this.$router.push(`${route}`)
    },
  },
};
</script>
<style lang='scss' scoped>
// 吸顶
.head{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 154px;
    background-color: #FFFFFF;
    .main{
        width: 1200px;
        margin: 0 auto;
        .head_top{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #CCCCCC;
            .text{
                font-size: 12px;
                font-weight: 500;
                color: #718096;
                margin-left: 24px;
                margin-bottom: 4px;
            }
            .phone{
                display: flex;
                align-items: center;
                font-size: 24px;
                color: #2753B1;
                img{
                    width: 24px;
                    height: 24px;
                    margin-top: 4px;
                }
            }
        }
        .head_bom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 73px;
            margin-top: 16px;
            box-sizing: border-box;
            li{
                width: 104px;
                height: 54px;
                line-height: 54px;
                text-align: center;
                margin: 0 25px;
                text-decoration: none;
                list-style-type: none;
                &:hover{
                    .link{
                        color: #2753B1;
                    }
                }
                .link{
                    display: inline-block;
                    position: relative;
                    line-height: normal;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 20px;
                    color: #4A5568;
                    &.active{
                        font-weight: bold;
                        color: #2753B1;
                        &:after{
                            content: '';
                            display: inline-block;
                            width: 14px;
                            height: 3px;
                            border-radius: 3px;
                            background-color: #2753B1;
                            position: absolute;
                            bottom: -6px;
                            left: 50%;
                            margin-left: -7px;
                        }
                    }
                }
            }
        }
    }
}

</style>
