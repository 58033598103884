<template>
  <div>
    <div class="qrcode" v-if="isqrcode" >
        <div class="qrcode_box" @mousedown="$emit('update:isqrcode', true)">
            <div class="title">
                <img src="../../views/home/image/phone.png" alt="">
                联系我们
            </div>
            <div class="con">
                <div class="le">
                    <div class="tit">联系我们</div>
                    <div class="text">
                        编辑 - 九月<br /> 
                        电话：18660133094<br />
                        地址：四川省成都市武侯区
                    </div>
                </div>
                <div class="rg">
                    <img src="../../views/home/image/erwm.png" alt="">
                    <div class="val">WhatsApp</div>
                </div>
            </div>
            <div class="btn" @mousedown.stop="$emit('update:isqrcode', false)">我知道了</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['isqrcode'],
    data(){
        return{
            
        }
    },
    mounted(){
        
    },
    methods:{
        
    }
}
</script>

<style lang='scss' scoped>
.qrcode{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 999;
}

.qrcode_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 700px;
    height: 500px;
    background: url('../../views/home/image/qrcode.png') no-repeat;
    background-size: 100%;
    padding: 60px 85px 48px;
    box-sizing: border-box;
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 36px;
        color: #4A5568;
        margin-bottom: 38px;
        img{
            margin-right: 12px;
        }
    }
    .con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .le{
            .tit{
                font-size: 30px;
                color: #4A5568;
                margin-bottom: 30px;
            }
            .text{
                font-size: 24px;
                color: #2753B1;
                line-height: 36px;
            }
        }
        .rg{
            img{
                margin-bottom: 8px;
            }
            .val{
                font-size: 24px;
                color: #718096;
                text-align: center;
            }
        }
    }
    .btn{
        width: 128px;
        height: 54px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        font-size: 20px;
        color: #2753B1;
        text-align: center;
        line-height: 54px;
        margin: 40px auto 0;
        cursor: pointer;
        &:hover{
            background: #FEDA58;
        }
    }
}
</style>