<template>
    <!-- 吸顶容器 -->
    <div class="left">
      <div class="left_box">
        <div class="left_a" @click="onKf">
          <!-- <img src="../../views/home/image/lea.png" alt=""> -->
          <div class="leftaa"></div>
          <!-- <div class="erwm" v-if="isleft">
    
          </div> -->
        </div>
        <div class="left_b" @click="onKf">
          <div class="leftbb"></div>
          <!-- <img src="../../views/home/image/leb.png" alt=""> -->
        </div>
        <div class="left_c" @click="goTop" :class="{show:isShow}">
          <div class="leftcc"></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        isShow:false,
        isleft:false,
    };
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        this.isShow = scrollTop > 250 ? true : false 
    },
    goTop(){
        document.querySelector('#onTop').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    onKf(){
      this.$emit('onKf')
    }
  },
};
</script>
<style lang='less' scoped>
// 吸顶
.left{
  position: fixed;
  top: 70%;
  right: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
}
.show{
  opacity: 1 !important;
}
.left_a{
  position: relative;
  width: 48px;
  height: 48px;
  background: #E2E8F0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;  
}
.left_a img{
  width: 24px;
  height: 24px;
}
.left_a:hover{
  background-color: #2753B1;
  .leftaa{
    background: url('../../views/home/image/leaa.png') no-repeat;
  }
}
.leftaa{
  width: 24px;
  height: 24px;
  background: url('../../views/home/image/lea.png') no-repeat;
}
.left_b{
  position: relative;
  width: 48px;
  height: 48px;
  background: #E2E8F0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.left_b img{
  width: 24px;
  height: 24px;
}
.left_b:hover{
  background-color: #2753B1;
  .leftbb{
    background: url('../../views/home/image/lebb.png') no-repeat;
  }
}
.leftbb{
  width: 24px;
  height: 24px;
  background: url('../../views/home/image/leb.png') no-repeat;
}
.left_c{
  position: relative;
  width: 48px;
  height: 48px;
  background: #E2E8F0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
}
.leftcc{
  width: 24px;
  height: 24px;
  background: url('../../views/home/image/top.png') no-repeat;
}
.left_c:hover{
  background-color: #2753B1;
  .leftcc{
    background: url('../../views/home/image/topb.png') no-repeat;
  }
}
.erwm{
  position: absolute;
  top: -12px;
  left: -122px;
  width: 100px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.erwm::after{
    border: 9px solid transparent;
    border-left: 9px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -18px;
    top: 38px;
    z-index: 2;
}
.erwm img{
  width: 80px;
  height: 80px;
}

</style>
