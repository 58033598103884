<template>
  <div class="foot"> 
    <div class="foot_top">
        <div class="item" @click="router('/mobile_serve')">服务项目</div>
        <div class="item" @click="router('/mobile_flowpath')">出版流程</div>
        <div class="item" @click="router('/mobile_book')">成书展示</div>
        <div class="item" @click="router('/mobile_new')">出版资讯</div>
        <div class="item" @click="router('/mobile_about')">关于我们</div>
        <!-- <div class="item">常见问题</div> -->
    </div>
    <div class="foot_bom">
        <div class="le">
            <img src="../../views/mobile/image/foot.png" alt="">
            <div class="text">联系我们</div>
            <div class="val" style="margin-bottom:8px;">出版热线：18660133094</div>
            <div class="val">联系地址：四川省成都市武侯区</div>
        </div>
        <div class="rg">
            <img src="../../views/mobile/image/erwm.png" alt="">
            <div class="what">WhatsApp</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    methods:{
        router(route){
            this.$router.push(`${route}`)
        }
    }
}
</script>

<style lang='scss' scoped>
.foot{
    width: 100%;
    .foot_top{
        width: 100%;
        display: flex;
        height: 40px;
        background: #1F283A;
        .item{
            height: 100%;
            font-size: 14px;
            color: #CCCCCC;
            flex: 1;
            text-align: center;
            line-height: 40px;
        }
    }
    .foot_bom{
        background: #1A202C;
        width: 100%;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
        .le{
            img{
                width: 88px;
                height: 36px;
                margin-bottom: 10px;
            }
            .text{
                font-weight: 700;
                font-size: 14px;
                color: #FFFFFF;
                margin-bottom: 10px;
            }
            .val{
                font-size: 14px;
                color: #CCCCCC;
            }
        }
        .rg{
            img{
                width: 80px;
                height: 80px;
                margin-bottom: 8px;
            }
            .what{
                font-size: 12px;
                color: #CCCCCC;
                text-align: center;
            }
        }
    }
}
</style>