import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
Vue.config.productionTip = false

// 引入公共css
import './assets/style/font.css';
// 引入公共组件
import mComponents from './components'
Vue.use(mComponents)

Vue.use(ElementUI, { locale });
import resetMessage from './assets/utils/reseMessage';
Vue.prototype.$message = resetMessage;
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)


import { Tab, Tabs } from 'vant';
import 'vant/lib/tab/style';
import 'vant/lib/tabs/style';
Vue.use(Tab);
Vue.use(Tabs);

//引入样式
import 'swiper/css/swiper.css'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
