import PageTop from './Page/Top.vue'
import PageFoot from './Page/Foot.vue'
import PageLeft from './Page/Left.vue'
import PageInformation from './Page/Information.vue'
import PageQrcode from './Page/Qrcode.vue'
import MTop from './m/Top.vue'
import MFoot from './m/Foot.vue'
import MQrcode from './m/Qrcode.vue'
export default {
    install(Vue) {
        Vue.component('PageTop', PageTop)
        Vue.component('PageFoot', PageFoot)
        Vue.component('PageLeft', PageLeft)
        Vue.component('PageInformation', PageInformation)
        Vue.component('PageQrcode', PageQrcode)
        Vue.component('MTop', MTop)
        Vue.component('MFoot', MFoot)
        Vue.component('MQrcode', MQrcode)
    }
}