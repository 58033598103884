import axios from '../utils/axios'
import apiConfig from '../utils/api.config'

axios.defaults.baseURL = apiConfig.baseURL

// 成书作品
export function getWorks(data) {
    return axios({
        method: 'POST',
        url: "/api/index/works",
        data
    })
}
// 新闻列表
export function getNews(data) {
    return axios({
        method: 'POST',
        url: "/api/index/newslist",
        data
    })
}
// 新闻详情
export function getNewsDetail(data) {
    return axios({
        method: 'POST',
        url: "/api/index/newsDetail",
        data
    })
}
