
// /**
//  * 日期格式化
//  * @param date 日期
//  * @param fmt 如'yyyy-MM-dd HH:mm:ss'
//  * @returns {*}
//  */
// export function formatDate(date, fmt) {
//   if (/(y+)/.test(fmt)) {
//     fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
//   }
//   const o = {
//     'M+': date.getMonth() + 1,
//     'd+': date.getDate(),
//     'H+': date.getHours(),
//     'm+': date.getMinutes(),
//     's+': date.getSeconds()
//   }
//   for (const k in o) {
//     if (new RegExp(`(${k})`).test(fmt)) {
//       const str = o[k] + ''
//       fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
//     }
//   }
//   return fmt
// }

// function padLeftZero(str) {
//   return ('00' + str).substr(str.length)
// }


/**
 * 存储localStorage
 */
export function setStore(name, content) {
    if (!name) return
    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export function getStore(name) {
    if (!name) return
    var value = window.localStorage.getItem(name)
    // if(typeof value == 'string'){
    //   value = JSON.parse(value)
    // }
    // if (value) {
    // console.log(value.typeof())
    // try {
    //   value = JSON.parse(value)
    // } catch (e) {
    //   value = ''
    // }
    return value
    // } 
}

/**
 * 删除localStorage
 */
export function removeStore(name) {
    if (!name) return
    window.localStorage.removeItem(name)
}
