<template>
  <!-- 退出弹窗 --> 
<div>
  <div class="inviteDialog" v-if="isout" @mousedown="$emit('update:isout', false)">
        <div class="inviteDialog_box" @mousedown.stop="$emit('update:isout', true)">
            
        </div>
    </div>

</div>
  

</template>

<script>

export default {
    props:['isout'],
    data(){
        return{
         
        }
    },
    created(){
        
    },
    mounted(){
    
    },
    methods:{
       

    }
}
</script>

<style lang='scss' scoped>

.inviteDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 99;
}
.inviteDialog_box{
    width: 400px;
    height: 208px;
    margin: 320px auto 0;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
}

</style>