<template>
  <div id="onTop">
    <page-top :navCurrentIndex="0"/>
    <div class="m_index">
      <div class="banner">
        <div class="ban_search">
          <el-input class="searchinput" v-model="phone" placeholder="请输入联系人手机号"></el-input>
          <div class="ban_btn" @click="isqrcode=true">立即定制</div>
        </div>
        <div class="ban_bom">
          <div class="ban_item">
            <img src="./image/icona.png" alt="">
            出书顾问 一对一个性化服务
          </div>
          <div class="ban_item">
            <img src="./image/iconb.png" alt="">
            国内外多家出版社深度合作
          </div>
          <div class="ban_item">
            <img src="./image/iconc.png" alt="">
            全程托管，进度透明可查
          </div>
          <div class="ban_item">
            <img src="./image/icond.png" alt="">
            统一标准收费，择优推荐
          </div>
        </div>
      </div>
      <div class="sectiona">
        <div class="title"><img src="./image/title.png" alt="">重点出版项目</div>
        <div class="sectiona_box">
          <div class="sectiona_car">
            <swiper ref="mySwiper" class="swiper top-swiper" :options="swiperOptions">
              <swiper-slide>
                <div class="book_box" @click="onMonograph">
                  <img src="./image/booka.png" alt="">
                 </div>
              </swiper-slide>
              <swiper-slide>
                <div class="book_box" @click="onPublish">
                  <img src="./image/bookb.png" alt="">
                 </div>
              </swiper-slide>
              <swiper-slide>
                <div class="book_box" @click="onEducation">
                  <img src="./image/bookc.png" alt="">
                 </div>
              </swiper-slide>
              <swiper-slide>
                <div class="book_box" @click="onUnit">
                  <img src="./image/bookd.png" alt="">
                 </div>
              </swiper-slide>
              <swiper-slide>
                <div class="book_box" @click="onPlaceunit">
                  <img src="./image/booke.png" alt="">
                 </div>
              </swiper-slide>
            </swiper>
            
          </div>
          <div class="book_arrowa prev" @click="prevFn('mySwiper')"></div>
          <div class="book_arrowb next" @click="nextFn('mySwiper')"></div>
        </div>
      </div>
      <div class="sectionb">
        <div class="title"><img src="./image/title.png" alt="">出版流程</div>
        <div class="sectbbtn" @click="onFlow">进一步了解<img src="./image/yjt.png" alt=""> </div>
      </div>
      <div class="sectionc">
        <div class="title"><img src="./image/title.png" alt="">成书展示</div>
        <div class="book_list">
          <div class="book_con">
            <el-carousel ref="cardShow" :autoplay='false' arrow="never" type="card" height="490px">
              <el-carousel-item v-for="(item,index) in bookList" :key="index">
                <div class="book_box">
                  <img :src="item.image" alt="">
                  <div class="book_tit">{{item.name}}</div>
                  <div class="book_btn" @click="isqrcode=true">点击咨询</div>
                </div>
              </el-carousel-item>
              <!-- <el-carousel-item>
                <div class="book_box">
                  <img src="./image/bookb.png" alt="">
                  <div class="book_tit">风吹百合岁月芳-张全秀2</div>
                  <div class="book_btn">点击咨询</div>
                </div>
              </el-carousel-item> -->
            </el-carousel>
          </div>
          <div class="book_arrowa" @click="arrowClick('prev')"></div>
          <div class="book_arrowb" @click="arrowClick('next')"></div>
        </div>
      </div>
      <div class="secione">
        <div class="title"><img src="./image/title.png" alt="">常见问题</div>
        <div class="problem">
          <div class="item">
            <div class="item_text">1、什么是专著？</div>
            <div class="item_btn" @click="onProblem(0)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">2、如何查询个人专著信息？</div>
            <div class="item_btn" @click="onProblem(1)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">3、什么是自费出版？</div>
            <div class="item_btn" @click="onProblem(2)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">4、自费出版的流程？</div>
            <div class="item_btn" @click="onProblem(3)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">5、书号、CIP定义与分类？</div>
            <div class="item_btn" @click="onProblem(4)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">6、书号申请流程？</div>
            <div class="item_btn" @click="onProblem(5)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">7、什么是著作权？</div>
            <div class="item_btn" @click="onProblem(6)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">8、评职称出书需要注意哪些问题？</div>
            <div class="item_btn" @click="onProblem(7)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">9、自费出书一般需要多少钱？</div>
            <div class="item_btn" @click="onProblem(8)">点击查看</div>
          </div>
          <div class="item">
            <div class="item_text">10、个人回忆录怎么出书?</div>
            <div class="item_btn" @click="onProblem(9)">点击查看</div>
          </div>
        </div>
      </div>
      <div class="sectiond">
        <div class="sectiond_con">
          <div class="sectiond_le"><img src="./image/sectiond1.png" alt=""></div>
          <div class="sectiond_rg">
            <div class="text">只要你想</div>
            <div class="val">普通人也可以成功出书</div>
            <div class="sectiond_btn" @click="isqrcode=true">
              <img src="./image/sectiond3.png" alt="">
              <span>点击咨询</span>
              <img src="./image/sectiond4.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tcproblem" v-if="isproblem">
      <div class="tcproblem_box">
        <div class="title">
          <span>常见问题</span>
          <div @click="onClose" class="close"><img src="./image/close.png" alt=""></div>
        </div>
        <div class="tcproblem_con">
          <div class="tcpro_tit">{{tcproblem[problemIndex].title}}</div>
          <div class="tcpro_text" v-html="tcproblem[problemIndex].text"></div>
        </div>
        <div class="tcproblem_btn">
          <div class="prex" :class="{noclick:problemIndex==0}" @click="onPrex">上一个</div>
          <div class="next" :class="{noclick:problemIndex==9}" @click="onNext">下一个</div>
        </div>
      </div>
    </div>
    <page-foot /> 
    <page-left @onKf="getKf" />
    <page-qrcode :isqrcode.sync="isqrcode"/>
    <!-- <page-information :isout.sync="isout"/> -->
  </div>
</template>

<script>
import { getWorks } from "@/assets/api/index";
export default {
    data(){
        return{
          navCurrentIndex:0,
          phone:'',
          swiperOptions:{
            slidesPerView : 4,
            spaceBetween : 24,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
          loop: true
          },
          isout:false,
          tcproblem:[
            {
              title:'1、什么是专著？',
              text:'<p>专著指的是针对某一专门研究题材的，是著作的别称。根据学术论文的长短，又可以分为单篇学术论文、系列学术论文和学术专著三种。一般而言，超过4—5万字的，可以称为学术专著。</p>'
            },
            {
              title:'2、如何查询个人专著信息？',
              text:"<p>个人专著只要是公开出版，不管采用的是哪种出版方式，都是要在新闻出版总署备案的，成功出版的个人专著，就可以在新闻出版总署查询。凡是在新闻出版总署查不到的个人专著，要不还没有成功出版，要不就是非正规出版。</p>"
            },
            {
              title:'3、什么是自费出版？',
              text:"<p>自费出版是指出版公司和出版社为社会各界爱好出书、想出书却又不知道怎么出书或没有写作能力和写作时间的人士及团体出版各种著作的人士提供方便的一种出书模式。</p>"
            },
            {
              title:'4、自费出版的流程？',
              text:"<p>书稿到位、书稿整理、资料提交、填写选题申报表、签定代理出版合同和付款、操作时间周期以及印刷、托运成书、发行宣传。</p>"
            },
            {
              title:'5、书号、CIP定义与分类？',
              text:"<p>书号（ ISBN ）：国际标准书号是区别“印刷品”与“图书”的标准，它是一本书“正规”的标志，是一本书的“准生证”。 2007 年起中国国际标准书号将由 10 位升级为 13 位，首位数字从“ 7 ”变为“ 978 ”。</p> <p>电子书号：指经新闻出版总署核准的音像电子出版社的书号（ ISBN ），用于出版电子图书，但无一般图书的图书在版编目（ CIP ）。 香港书号：指香港出版社的书号。需要注意的是，在大陆，它一般被认为是非法出版物，不能在内地印刷和发行（经特别程序除外），不能用来评职称。其价格从几百元到几千元不等。</p> <p>CIP ：即图书在版编目，是指一本书经过了国家新闻出版总署的注册，拥有了完全合法的身份。这可以到出书网</p><p>www.xinhuachushu.com 及 http://www.cppinfo.cn 查询，查询结果可以判定一本书的真伪——是否为正式出版物，是否为假号、过期书号、丛书号、香港书号等。</p>"
            },
            {
              title:'6、书号申请流程？',
              text:"<p>书号申请流程一般是这样的：提交申报选题-出版社审核通过-拟定合同，缴纳书号费用及其他费用-书稿三审三校-出版社提供书号条形码上CIP数据备案-书籍设计印刷等等。</p>"
            },
            {
              title:'7、什么是著作权？',
              text:"<p>著作权过去称为版权。版权最初的涵义是copyright（版和权），也就是复制权。此乃因过去印刷术的不普及，当时社会认为附随于著作物最重要之权利莫过于将之印刷出版之权，故有此称呼。不过随着时代演进及科技的进步，著作的种类逐渐增加。世界上第一部版权法英国《安娜法令》开始保护作者的权利，而不仅仅是出版者的权利。1791年，法国颁布了《表演权法》，开始重视保护作者的表演权利。1793年又颁布了《作者权法》，作者的精神权利得到了进一步的重视。版权一词已渐渐不能含括所有著作物相关之权利内容。19世纪后半叶，日本融合大陆法系的著作权法中的作者权，以及英美法系中的版权，制定了《日本著作权法》，采用了“著作权”的称呼。</p>"
            },
            {
              title:'8、评职称出书需要注意哪些问题？',
              text:"<p>1、了解政策法规：出书之前需要了解当地的出版政策法规，确保符合国家和地方的规定。尤其是对于涉及到敏感话题或者有争议的话题时，更需要注意。</p> <p>2、选择合适的出版社：不同出版社对于出版内容和质量要求不同，需要根据自身需求选择合适的出版社。同时，也要注意出版周期和价格等因素。</p> <p> 3、合理安排时间：出书是一项比较繁琐的工作，需要提前规划好时间和进度，以免影响正常工作和生活。</p> <p>4、做好费用预算：在选择出版形式时，需要根据自己的经济状况和需求来合理规划。同时也要注意支付方式和时间等问题。</p> <p> 5、认真核对书稿：在出书之前，需要仔细核对书稿内容和格式等方面是否符合要求，以免出现不必要的问题和麻烦。</p>"
            },
            {
              title:'9、自费出书一般需要多少钱？',
              text:"<p>1、书号费用 </p> <p style='text-indent:2em;'>书号是个人出书流程不能缺少的重要条件，同时也是出书费用中占比比较大的费用开支，用的书号有单书号和丛书号两种，其中单书号是个人自费出书用得较多的，出版方式比较快且方便，现在市场价格大概在4-6W左右，丛书号就相对便宜的多，只需要1-2W元钱，但丛书号出版周期比较长。</p> <p> 2、文稿排版和审核校对 </p> <p style='text-indent:2em;'> 这一环节同样是个人出书中不能省略的环节，为了保证日后出版书籍的质量，现在大部分的个人出书都是选择和出版公司合作。文稿的排版、错误修正和审核校对的问题，这部分费用根据提供的文稿字数确定，整个流程总共费用大概在几千到上万元以上。</p> <p> 3、书籍出版印刷 </p> <p style='text-indent:2em;'>书号和文稿的排版审核工作完成以后就是最后一步出版印刷，这一流程的费用主要是根据选用的纸张和印刷方式不同，费用也不一样，不过印刷的数量越多，相对应的单价越便宜，这部分大概也是需要几千甚至上万不等。 </p> <p style='text-indent:2em;'> 整个流程下来，个人自费出书的费用大概在几万块左右。</p>"
            },
            {
              title:'10、个人回忆录怎么出书?',
              text:"<p style='text-indent:2em;'>回忆录出书很简单，比较流行的便是电子书出版。在互联网上自费出书还属主流，回忆录等书籍也比较适合自费出书。</p> <p style='text-indent:2em;'> 个人出书回忆录出书的价格，首先出书要看书的字数，字数便决定书的页数，页面排版，纸张，印刷，和审稿的问题，当然字数越多成本上就越贵。除此，便是书号费用，正规单书号都要在万元，丛书号要几千不等。当然也可以不要书号，这样的书只能当做内部资料不能发行。</p>"
            },
          ],
          problemIndex:0,
          isproblem:false,
          bookList:[],
          isqrcode:false
        }
    },
    created(){
      this.getWorks()
    },
    methods:{
        async getWorks() {
          const res = await getWorks({
            page:1,
            pageSize:5
          });
          if (res.code==200) {
            console.log(res.data);
            this.bookList=res.data.list
          }
        },
        prevFn(name) {
          const swiper = this.$refs[name].$swiper;
          swiper.slidePrev();
        },
        nextFn(name) {
          const swiper = this.$refs[name].$swiper;
          swiper.slideNext();
        },
        arrowClick(val){
          if(val === 'next') {
            this.$refs.cardShow.next()
          } else {
            this.$refs.cardShow.prev()
          }
        },
        onMonograph(){
            this.$router.push('/serviceitem/monograph')
        },
        onPublish(){
            this.$router.push('/serviceitem/publish')
        },
        onEducation(){
            this.$router.push('/serviceitem/education')
        },
        onUnit(){
            this.$router.push('/serviceitem/units')
        },
        onPlaceunit(){
            this.$router.push('/serviceitem/placeunit')
        },
        onFlow(){
          this.$router.push('/flowpath')
        },
        onPrex(){
          if(this.problemIndex!=0){
            this.problemIndex--
          }
        },
        onNext(){
          if(this.problemIndex!=9){
            this.problemIndex++
          }
        },
        onProblem(i){
          this.problemIndex = i
          this.isproblem = true
        },
        onClose(){
          this.isproblem = false
        },
        getKf(){
          this.isqrcode = true
        }
        
    }
}
</script>

<style lang='scss' scoped>
.m_index{
  padding-top: 154px;
  box-sizing: border-box;
  .banner{
    width: 100%;
    height: 300px;
    background: url('./image/bannera.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 93px;
    box-sizing: border-box;
    .ban_search{
      width: 612px;
      height: 54px;
      margin: 0 auto;
      border: 2px solid #FECA11;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      overflow: hidden;
      .searchinput{
        width: 484px;
        ::v-deep .el-input__inner{
          width: 484px;
          height: 50px;
          line-height: 50px;
          padding-left: 24px;
          border: 0;
          border-radius: 0;
          font-size: 16px;
          box-sizing: border-box;
          &::placeholder{
            font-size: 16px;
          }
        } 
      }
      .ban_btn{
        width: 128px;
        height: 54px;
        background-color: #FECA11;
        border-radius: 4px;
        text-align: center;
        line-height: 54px;
        font-weight: 500;
        font-size: 20px;
        color: #2753B1;
        cursor: pointer;
      }
    }
    .ban_bom{
      width: 900px;
      display: flex;
      align-items: center;
      margin: 40px auto 0;
      .ban_item{
        width: 230px;
        border-right: 1px solid #E2E8F0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        color: #E2E8F0;
        img{
          margin-right: 9px;
        }
        &:last-child{
          border-right: 0;
        }
      }
    }
  }
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 36px;
    color: #4A5568;
    img{
      margin-right: 12px;
    }
  }
  .book_arrowa{
        background: url('./image/arrowa.png') no-repeat;
        width: 48px;
        height: 48px;
        position: absolute;
        top: 50%;
        left: 38px;
        transform: translateY(-50%);
        &:hover{
          background: url('./image/arrowaa.png') no-repeat;
        }
  }
  .book_arrowb{
        background: url('./image/arrowb.png') no-repeat;
        width: 48px;
        height: 48px;
        position: absolute;
        top: 50%;
        right: 38px;
        transform: translateY(-50%);
        &:hover{
          background: url('./image/arrowbb.png') no-repeat;
        }
  }
  
  .sectiona{
    width: 100%;
    padding: 80px 0 160px;
    box-sizing: border-box;
    .sectiona_box{
      position: relative;
      width: 1400px;
      margin: 85px auto 0;
      .sectiona_car{
        width: 1200px;
        margin: 0 auto;
        .book_box{
          width: 282px;
          height: 380px;
          border-radius: 8px;
          overflow: hidden;
          transition: all 0.3s;
          display: flex;
          align-items: flex-end;
          &:hover{
            transform: translateY(-20px);
            // box-shadow: 10px 0px 10px 0px rgba(0,0,0,0.5);          
          }
          img{
            width: 100%;
            height: 360px;
          }
        }
      }
      
      
      
    }
  }
  .sectionb{
    position: relative;
    width: 100%;
    height: 680px;
    padding-top: 80px;
    box-sizing: border-box;
    background: url('./image/sectionb.png') no-repeat center;
    background-size: 100%;
    background-color: #f7fafc;
    .sectbbtn{
      position: absolute;
      left: 50%;
      bottom: 48px;
      transform: translateX(-50%);
      width: 178px;
      height: 54px;
      background: #2753B1;
      border-radius: 363px 363px 363px 363px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      cursor: pointer;
      &:hover{
        background-color: #4A6FBD;
      }
    }
  }
  .sectionc{
    padding: 80px 0;
    box-sizing: border-box;
    background-color: #F0F4F9;
    .book_list{
      position: relative;
      width: 1400px;
      margin: 40px auto 0;
      .book_con{
        width: 1200px;
        margin: 0 auto;
        ::v-deep .el-carousel__item{
          background-color: #FFFFFF;
        }
        ::v-deep .el-carousel__item--card{
          display: flex;
          justify-content: center;
        }
        .book_box{
          width: 538px;
          height: 490px;
          padding: 40px 40px 33px;
          margin: 0;
          border-radius: 8px;
          box-sizing: border-box;
          background-color: #FFFFFF;
          img{
            width: 100%;
            height: 290px;
            border-radius: 4px;
          }
          .book_tit{
            font-weight: 500;
            font-size: 24px;
            color: #718096;
            margin: 14px auto 23px;
            text-align: center;
          }
          .book_btn{
            width: 128px;
            height: 54px;
            background: #FECA11;
            text-align: center;
            line-height: 54px;
            border-radius: 4px 4px 4px 4px;
            font-weight: 500;
            font-size: 20px;
            color: #2753B1;
            margin: 0 auto;
            &:hover{
              background: #FEDA58;
            }
          }
        }
      }
    }
  }
  .sectiond{
    background-color: #E7E7E7;
    padding: 80px 0;
    box-sizing: border-box;
    .sectiond_con{
      width: 948px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sectiond_le{
        width: 528px;
        height: 408px;
      }
      .sectiond_rg{
        .text{
          font-weight: 700;
          font-size: 36px;
          color: #1A202C;
          line-height: 54px;
        }
        .val{
          font-weight: 700;
          font-size: 36px;
          color: #1A202C;
          line-height: 54px;
          background: url('./image/sectiond2.png') no-repeat;
          background-position: bottom;
        }
        .sectiond_btn{
          margin-top: 42px;
          width: 204px;
          height: 54px;
          background: #FECA11;
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover{
            background: #FEDA58;
          }
          span{
            margin: 0 10px;
            font-weight: 500;
            font-size: 20px;
            color: #2753B1;
          }
        }
      }
    }
  }
  .secione{
    padding: 80px 0 65px;
    box-sizing: border-box;
    background-color: #F0F4F9;
    .problem{
      width: 1200px;
      margin: 45px auto 0;
      display: flex;
      flex-wrap: wrap;
      .item{
        width: 576px;
        height: 118px;
        background: #F7FAFC;
        box-shadow: 2px 6px 10px 0px rgba(102,102,102,0.2);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 48px;
        margin-bottom: 48px;
        padding: 32px 60px;
        box-sizing: border-box;
        .item_text{
          width: 300px;
          font-weight: 700;
          font-size: 24px;
          color: #4A5568;
          line-height: 32px;
        }
        .item_btn{
          width: 128px;
          height: 54px;
          background: #FECA11;
          border-radius: 4px 4px 4px 4px;
          font-size: 20px;
          color: #2753B1;
          text-align: center;
          line-height: 54px;
          cursor: pointer;
          &:hover{
             background: #FEDA58;
          }
        }
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
  
}
.tcproblem{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    .tcproblem_box{
      width: 700px;
      height: 560px;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      padding: 24px 48px 48px;
      box-sizing: border-box;
      .title{
        width: 100%;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 30px;
        color: #1A202C;
        margin-bottom: 24px;
        border-bottom: 1px solid #CBD5E0;
        .close{
          cursor: pointer;
        }
      }
      .tcproblem_con{
        width: 100%;
        height: 350px;
        .tcpro_tit{
          font-weight: 700;
          font-size: 24px;
          color: #1A202C;
          margin-bottom: 16px;
        }
        .tcpro_text{
          font-size: 20px;
          color: #4A5568;
          line-height: 36px;
          width: 100%;
          height: 280px;
          overflow: auto; 
        }
      }
      .tcproblem_btn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .prex{
          width: 140px;
          height: 54px;
          background: #FECA11;
          border-radius: 4px 4px 4px 4px;
          font-size: 20px;
          color: #2753B1;
          text-align: center;
          line-height: 54px;
          margin-right: 40px;
          cursor: pointer;
          &:hover{
            background: #FEDA58;
          }
        }
        .next{
          width: 140px;
          height: 54px;
          background: #FECA11;
          border-radius: 4px 4px 4px 4px;
          font-size: 20px;
          color: #2753B1;
          text-align: center;
          line-height: 54px;
          cursor: pointer;
          &:hover{
            background: #FEDA58;
          }
        }
        .noclick{
          background: #EBEFF5;
          color: #A0AEC0;
          &:hover{
            background: #EBEFF5;
          }
        }
      }
    }
  }
</style>