<template>
  <div>
    <div class="qrcode" v-if="isqrcode" >
        <div class="qrcode_box" @mousedown="$emit('update:isqrcode', true)">
            <div class="tit">
                <img src="../../views/mobile/image/phone.png">
                联系我们
            </div>
            <div class="text">编辑 - 九月</div>
            <div class="text">电话：18660133094</div>
            <div class="text">地址：四川省成都市武侯区</div>
            <div class="erwm"><img src="../../views/mobile/image/erwm2.png" alt=""></div>
            <div class="val">WhatsApp</div>
            <div class="btn" @mousedown.stop="$emit('update:isqrcode', false)">我知道了</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['isqrcode'],
    data(){
        return{
            
        }
    },
    mounted(){
        
    },
    methods:{
        
    }
}
</script>

<style lang='scss' scoped>
.qrcode{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 999;
}

.qrcode_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
    height: 420px;
    background: url('../../views/mobile/image/qrcode.png') no-repeat;
    background-position: bottom;
    background-size: 100% 214px;
    background-color: #FFFFFF;
    padding: 40px 40px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    .tit{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 24px;
        color: #4A5568;
        margin-bottom: 16px;
        img{
            width: 36px;
            height: 36px;
            margin-right: 6px;
        }
    }
    .text{
        color: #2753B1;
        line-height: 24px;
    }
    .erwm{
        margin: 20px 0 10px;
        text-align: center;
        img{
            width: 120px;
            height: 120px;
        }
    }
    .val{
        color: #718096;
        text-align: center;
        margin: 10px 0 20px;
    }
    .btn{
        width: 140px;
        height: 40px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        line-height: 40px;
        color: #2753B1;
        margin: 0 auto;
    }
}
</style>