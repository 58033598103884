import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import Serviceitem from '../views/home/serviceitem.vue'
import mobile_home from '../views/mobile/mobile_index.vue'
Vue.use(VueRouter)
// 跳转同一个页面
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/serve.vue')
  },
  // 服务项目
  {
    path: '/serviceitem',
    name: 'serviceitem',
    component: Serviceitem,
    children: [
      {
        path: '/serviceitem/monograph',
        component: () => import('../views/home/serviceitem/monograph.vue'),
      },
      {
        path: '/serviceitem/units',
        component: () => import('../views/home/serviceitem/units.vue'),
      },
      {
        path: '/serviceitem/publish',
        component: () => import('../views/home/serviceitem/publish.vue'),
      },
      {
        path: '/serviceitem/placeunit',
        component: () => import('../views/home/serviceitem/placeunit.vue'),
      },
      {
        path: '/serviceitem/education',
        component: () => import('../views/home/serviceitem/education.vue'),
      },
    ]
  },
  {
    path: '/flowpath',
    name: 'flowpath',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/flowpath.vue')
  },
  {
    path: '/book',
    name: 'book',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/book.vue')
  },
  {
    path: '/new',
    name: 'new',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/new.vue')
  },
  {
    path: '/newdetails/:id',
    name: 'newdetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/newdetails.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/about.vue')
  },
  // 移动端
  {
    path: '/m',
    name: 'mobile_home',
    component: mobile_home,
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_problem',
    name: 'mobile_problem',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_problem.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_serve',
    name: 'mobile_serve',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_serve.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_flowpath',
    name: 'mobile_flowpath',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_flowpath.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_new',
    name: 'mobile_new',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_new.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_book',
    name: 'mobile_book',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_book.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_about',
    name: 'mobile_about',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_about.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_newdetails/:id',
    name: 'mobile_newdetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_newdetails.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/mobile_serveitem/:id',
    name: 'mobile_serveitem',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/mobile_serveitem.vue'),
    meta: { type: 'mobile' }
  },
]

// 路由跳转滚轮到顶部
const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let concatPath = to.fullPath.substring(1)
  // 移动端访问PC端页面，需要跳转到移动端
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && to.meta.type !== 'mobile') {
    const path = to.fullPath !== '/' ? `mobile_${concatPath}` : '/m'
    next(path)
  } else {
    next();
  }
});

export default router
