<template>
    <div id="onTop">
        <page-top :navCurrentIndex="0"/>
        <div class="serviceitem">
            <div class="service_top">
                <div class="top_con">
                    <router-link :to="item.link" class="top_item" :class="{typeClass:istype==index}" v-for="(item,index) in serveList" :key="index" @click.native="onType(index)">
                        {{item.title}}
                    </router-link>
                </div>
            </div>
            <div class="service_box">
                <router-view></router-view>
            </div>
        </div>
        <page-left @onKf="isqrcode=true" />
        <page-qrcode :isqrcode.sync="isqrcode"/>
        <page-foot /> 
    </div>
</template>

<script>
export default {
    data(){
        return{
            serveList:[
                {
                    title:'职称专著',
                    link:'/serviceitem/monograph'
                },
                {
                    title:'个人出版',
                    link:'/serviceitem/publish'
                },
                {
                    title:'教育机构',
                    link:'/serviceitem/education'
                },
                {
                    title:'企事业单位',
                    link:'/serviceitem/units'
                },
                {
                    title:'地方单位',
                    link:'/serviceitem/placeunit'
                },
                
            ],
            istype:0,
            isqrcode:false
        }
    },
    mounted(){
        let isurlt =window.location.href.substr(-5)
        if(isurlt=='blish'){
            this.istype = 1
        }else if(isurlt=='ation'){
            this.istype = 2
        }else if(isurlt=='units'){
            this.istype = 3
        }else if(isurlt=='eunit'){
            this.istype = 4
        }else{
            this.istype = 0
        }
        console.log(isurlt);
    },
    methods:{
        onType(index){
            this.istype = index
        },
    }
}
</script>

<style lang='scss' scoped>
.serviceitem{
    padding-top: 154px;
    box-sizing: border-box;
    .service_top{
        width: 100%;
        height: 64px;
        background-color: #F7FAFC;
        border-top: 1px solid #E2E8F0;
        .top_con{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            padding: 0 152px;
            box-sizing: border-box;
            .top_item{
                display: inline-block;
                height: 100%;
                line-height: 64px;
                margin-right: 140px;
                font-weight: 500;
                font-size: 16px;
                color: #A0AEC0;
                text-decoration: none;
                &:hover{
                    color: #2753B1;
                    font-weight: 600;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            .typeClass{
                color: #2753B1;
                font-weight: 600;
            }
        }
    }
    .service_box{
        width: 100%;
    }
}
</style>