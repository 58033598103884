<template>
  <div class="head">
    <div class="head_img" @click="onHome"><img src="../../views/mobile/image/logo.png" alt=""></div>
    <div class="myuser" @click="ismenu=true">
      <img src="../../views/mobile/image/close.png" alt="" v-if="ismenu" @click.stop="ismenu=false">
      <img src="../../views/mobile/image/menu.png" alt=""  v-else>
    </div>
    <div class="login_own" v-if="ismenu">
      <div class="own">
        <span v-for="(item, index) in navList" :key="index" @click="directTo(item.route)">{{item.name}}</span>
      </div>
    </div>
    <div class="maskA" v-if="ismenu" @click="ismenu=false"></div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            ismenu:false,
            navList:[
            {
                name:'网站首页',
                route:'/m'
            },
            {
                name:'服务项目',
                route:'/mobile_serve'
            },
            {
                name:'出版流程',
                route:'/mobile_flowpath'
            },
            {
                name:'成书展示',
                route:'/mobile_book'
            },
            {
                name:'出版资讯',
                route:'/mobile_new'
            },
            {
                name:'关于我们',
                route:'/about'
            },
            {
                name:'常见问题',
                route:'/mobile_problem'
            },
        ],
        }
    },
    methods:{
      directTo(route){
        this.$router.push(`${route}`)
      },
      onHome(){
        this.$router.push('/')
      }
    }
}
</script>

<style lang='scss' scoped>
.head{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  box-sizing: border-box;
  .head_img img{
    width: 90px;
    height: 36px;
  }
  .login_own{
    // height: 100vh;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #FFFFFF;
    .own{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      span{
        width: 100%;
        height: 44px;
        line-height: 44px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: define-font;
        font-weight: 400;
        border-bottom: 1px solid #E5E5E5;
        &:last-child{
          border-bottom: 0;
        }
      }
    } 
  }
  .myuser img{
    width: 24px;
    height: 24px;
  }
  .maskA {
    position: fixed;
    left: 0px;
    top: 50px;
    width: 100%;
    height: 1000px;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>