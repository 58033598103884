<template>
    <div class="foot">
        <div class="foot_box">
            <div class="foota">
                <div class="foot_le"><img src="../../views/home/image/foot.png" alt=""></div>
                <ul class="foot_rg">
                    <li @click="router('/')">· 网站首页</li>
                    <li @click="router('/serve')">· 服务项目</li>
                    <li @click="router('/flowpath')">· 出版流程</li>
                    <li @click="router('/book')">· 成书展示</li>
                    <li @click="router('/new')">· 出版资讯</li>
                    <li @click="router('/about')">· 关于我们</li>
                </ul>
            </div>
            <div class="footb">出版热线: <span>18660133094</span></div>
            <div class="footc">地址：四川省成都市武侯区</div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },

  methods: {
    router(route){
        this.$router.push(`${route}`)
    }
  },
};
</script>
<style lang='scss' scoped>
.foot{
    width: 100%;
    background-color: #1A202C;
    padding: 60px 0 46px;
    box-sizing: border-box;
    .foot_box{
        width: 1200px;
        margin: 0 auto;
        .foota{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .foot_rg{
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                color: #A0AEC0;
                li{
                    margin-right: 20px;
                    list-style-type: none;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                        color: #E2E8F0;
                    }
                }
            }
        }
        .footb{
            width: 100%;
            border-bottom: 1px solid #4A5568;
            display: flex;
            justify-content: flex-end;
            padding: 18px 0 10px;
            box-sizing: border-box;
            font-size: 16px;
            color: #E2E8F0;
            display: flex;
            align-items: center;
            span{
                font-size: 36px;
                color: #E2E8F0;
                margin-left: 8px;
            }
        }
        .footc{
            padding-top: 11px;
            font-size: 14px;
            color: #A0AEC0;
            box-sizing: border-box;
        }
    }
}

</style>
