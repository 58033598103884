<template>
  <div>
    <m-top />
    <div class="index">
      <div class="banner">
        <div class="banner_img"><img src="./image/bannera.png" alt=""></div>
        <div class="banner_box">
          <div class="item">
            <img src="./image/booka.png" alt="">
            出书顾问 一对一个性化服务
          </div>
          <div class="item">
            <img src="./image/bookb.png" alt="">
            国内外多家出版社深度合作
          </div>
          <div class="item">
            <img src="./image/bookc.png" alt="">
            全程托管，进度透明可查
          </div>
          <div class="item">
            <img src="./image/bookd.png" alt="">
            统一标准收费，择优推荐
          </div>
          <div class="banner_btn" @click="isqrcode=true">立即定制</div>
        </div>
      </div>
      <div class="sectiona">
        <div class="title"><img src="./image/title.png" alt="">重点出版项目</div>
        <div class="sectiona_box">
          <div class="item" @click="onServeItem(0)"><img src="./image/book1.png" alt=""></div>
          <div class="item" @click="onServeItem(1)"><img src="./image/book2.png" alt=""></div>
          <div class="item" @click="onServeItem(2)"><img src="./image/book3.png" alt=""></div>
          <div class="item" @click="onServeItem(3)"><img src="./image/book4.png" alt=""></div>
          <div class="item" @click="onServeItem(4)"><img src="./image/book5.png" alt=""></div>
          <div class="btn" @click="onServeItem(0)">进一步了解</div>
        </div>
      </div>
      <div class="sectionb">
        <div class="sectionb_con">
          <div class="title" style="margin-bottom:24px;"><img src="./image/title.png" alt="">出版流程</div>
          <div class="sectionb_item">
            <span>1</span>
            电子书稿初审，确定出版方案
          </div>
          <div class="sectionb_item">
            <span>2</span>
            签订出版合同，支付出版费用
          </div>
          <div class="sectionb_item">
            <span>3</span>
            稿件三审三校，书籍设计排版
          </div>
          <div class="sectionb_item">
            <span>4</span>
            书号申请办理，电传作者审验
          </div>
          <div class="sectionb_item">
            <span>5</span>
            审校印前书稿，核发书号验证
          </div>
          <div class="sectionb_item">
            <span>6</span>
            成书交付作者，媒体推广宣传
          </div>
          <div class="btn" @click="onFlow">进一步了解</div>
        </div>
      </div>
      <div class="sectionc">
        <div class="title" style="margin-bottom:24px;"><img src="./image/title.png" alt="">出版流程</div>
        <div class="cur">
          <div class="cur_con">
            <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
              <swiper-slide v-for="(item, index) in bookList" :key="index">
                <div class="cur_box">
                  <img class="banner" width="100%" :src="item.image">
                  <div class="text">{{item.name}}</div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="cur_btn" @click="isqrcode=true">点击咨询</div>
           <div class="book_arrowa prev" @click="prevFn('mySwiper')"></div>
          <div class="book_arrowb next" @click="nextFn('mySwiper')"></div>
        </div>
      </div>
      <div class="sectiond">
        <div class="title" style="margin-bottom:30px;"><img src="./image/title.png" alt="">常见问题</div>
        <div class="sectiond_boxa">
          <div class="item">
            <div class="text">1、什么是专著？</div>
            <div class="btn" @click="onProblem">点击查看</div>
          </div>
          <div class="item">
            <div class="text">2、如何查询个人专著信息？</div>
            <div class="btn" @click="onProblem">点击查看</div>
          </div>
          <div class="item">
            <div class="text">3、什么是自费出版？</div>
            <div class="btn" @click="onProblem">点击查看</div>
          </div>
          <div class="item">
            <div class="text">4、自费出版的流程？</div>
            <div class="btn" @click="onProblem">点击查看</div>
          </div>
          <div class="item">
            <div class="text">5、书号、CIP定义与分类？</div>
            <div class="btn" @click="onProblem">点击查看</div>
          </div>
          <div class="item">
            <div class="text">6、书号申请流程？</div>
            <div class="btn" @click="onProblem">点击查看</div>
          </div>
        </div>
        <div class="more" @click="onProblem">查看更多</div> 
      </div>
      <div class="sectione">
        <div class="tit">
          <div>只要你想<br /> 普通人也可以成功出书</div>
        </div>
        <img src="./image/sectione2.png" alt="">
        <div class="btn" @click="isqrcode=true">
          <img src="./image/sectione3.png" alt="">
          <span>点击咨询</span>
          <img src="./image/sectione4.png" alt="">
        </div>
      </div>
    </div>
    <m-foot />
    <m-qrcode :isqrcode.sync="isqrcode"/>
  </div>
</template>

<script>
import { getWorks } from "@/assets/api/index";
export default {
    data(){
        return{
          swiperOptions: {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            loop: true
          },
          bookList:[],
          isqrcode:false
        }
    },
    created(){
      this.getWorks()
    },
    methods:{
      async getWorks() {
          const res = await getWorks({
            page:1,
            pageSize:5
          });
          if (res.code==200) {
            console.log(res.data);
            this.bookList=res.data.list
          }
      }, 
      prevFn(name) {
          const swiper = this.$refs[name].$swiper;
          swiper.slidePrev();
      },
      nextFn(name) {
          const swiper = this.$refs[name].$swiper;
          swiper.slideNext();
      },
      onFlow(){
        this.$router.push('/mobile_flowpath')
      },
      onProblem(){
        this.$router.push('/mobile_problem')
      },
      onServeItem(type){
        this.$router.push({
            path: `/mobile_serveitem/${type}`
        })
      }
    }
}
</script>

<style lang='scss' scoped>
.index{
  width: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  .banner{
    position: relative;
    width: 100%;
    height: 428px;
    background: #F7FAFC;
    .banner_img img{
      width: 100%;
      height: 220px;
    }
    .banner_box{
      position: absolute;
      bottom: 48px;
      left: 50%;
      transform: translateX(-50%);
      width: 335px;
      height: 240px;
      background: #FFFFFF;
      box-shadow: 2px 3px 8px 0px rgba(102,102,102,0.1);
      border-radius: 8px 8px 8px 8px;
      padding: 24px 51px 28px;
      box-sizing: border-box;
      .item{
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #718096;
        margin-bottom: 12px;
        img{
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
      .banner_btn{
        margin: 16px auto 0;
        width: 160px;
        height: 40px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #FECA11;
        font-size: 16px;
        color: #2753B1;
        text-align: center;
        line-height: 40px;
      }
    }
  }
  .sectiona{
    width: 100%;
    background: #FFFFFF;
    padding: 48px 40px 56px;
    box-sizing: border-box;
    .sectiona_box{
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item{
        width: 100%;
        // height: 180px;
        margin-bottom: 30px;
        img{
          width: 100%;
          // height: 100%;
        }
      }
      .btn{
        margin: 40px auto 0;
        width: 180px;
        height: 48px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #FECA11;
        font-size: 16px;
        color: #2753B1;
        text-align: center;
        line-height: 48px;
      }
    }
  }
  .title{
    font-weight: 700;
    font-size: 20px;
    color: #4A5568;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
  .sectionb{
    padding: 48px 30px 30px;
    box-sizing: border-box;
    background: #F7FAFC;
    .sectionb_con{
      width: 100%;
      background: url('./image/botjt.png') no-repeat;
      background-size: 100%;
      background-position: bottom;
      padding-bottom: 150px;
      box-sizing: border-box;
      .sectionb_item{
        width: 100%;
        height: 70px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #4A5568;
        box-shadow: 2px 3px 8px 0px rgba(102,102,102,0.1);
        border-radius: 8px;
        margin-bottom: 16px;
        span{
          width: 28px;
          height: 28px;
          background: #FECA11;
          border-radius: 50%;
          font-weight: 700;
          font-size: 20px;
          color: #2753B1;
          text-align: center;
          line-height: 28px;
          margin-right: 8px;
        }
      }
      .btn{
        margin: 24px auto 0;
        width: 180px;
        height: 48px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        color: #2753B1;
        text-align: center;
        line-height: 48px;
      }
    }
  }
  .sectionc{
    padding: 48px 16px;
    box-sizing: border-box;
    background: #F0F4F9;
    .cur{
      position: relative;
      width: 100%;
      padding: 0 38px;
      box-sizing: border-box;
      .cur_con{
        width: 100%;
        .cur_box{
          background: #FFFFFF;
          padding: 20px 13px 24px;
          border-radius: 8px;
          box-sizing: border-box;
          img{
            width: 100%;
            height: 152px;
            border-radius: 4px;
            margin-bottom: 16px;
          }
          .text{
            font-size: 18px;
            color: #718096;
            text-align: center;
          }
        }
      }
      .cur_btn{
        width: 112px;
        height: 40px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        margin: 24px auto 0;
        color: #2753B1;
        text-align: center;
        line-height: 40px;
      }
      .book_arrowa{
        background: url('./image/arrowa.png') no-repeat;
        width: 30px;
        height: 30px;
        background-size: 100%;
        position: absolute;
        top: 35%;
        left: 0;
      }
      .book_arrowb{
        background: url('./image/arrowb.png') no-repeat;
        width: 30px;
        height: 30px;
        background-size: 100%;
        position: absolute;
        top: 35%;
        right: 0;
      }
    }
  }
  .sectiond{
    padding: 48px 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    .item{
      width: 100%;
      height: 88px;
      background: #F7FAFC;
      box-shadow: 2px 4px 6px 0px rgba(153,153,153,0.2);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      box-sizing: border-box;
      margin-bottom: 24px;
      .text{
        font-weight: 700;
        font-size: 18px;
        color: #4A5568;
        width: 170px;
      }
      .btn{
        width: 96px;
        height: 40px;
        background: #FECA11;
        border-radius: 4px 4px 4px 4px;
        color: #2753B1;
        text-align: center;
        line-height: 40px;
      }
    }
    .more{
      width: 164px;
      height: 48px;
      background: #FECA11;
      border-radius: 4px 4px 4px 4px;
      color: #2753B1;
      text-align: center;
      line-height: 48px;
      border-radius: 4px;
      margin: 30px auto 0;
    }
  }
  .sectione{
    padding: 48px 28px;
    box-sizing: border-box;
    background: #F0F4F9;
    .tit{
      width: 240px;
      font-weight: 700;
      font-size: 24px;
      color: #1A202C;
      text-align: center;
      margin: 0 auto 24px;
      background: url('./image/sectione1.png') no-repeat;
      background-size: 100%;
      background-position: bottom;
    }
    img{
      width: 100%;
      // height: 255px;
    }
    .btn{
      width: 140px;
      height: 40px;
      background: #FECA11;
      margin: 24px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      span{
        color: #2753B1;
        margin: 0 8px;
      }
      img{
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>